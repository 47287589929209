import React from "react"
import { UsePromoCodeContext } from "../contexts/PromoCodeContext"
import { PROMO_CODE_DEFAULT, PROMO_CODE_INVALID } from "../constants"

const PromoCodeInput = () => {
  const { promoCodeId, promoCode, setPromoCode, promoCodeValid } = UsePromoCodeContext()

  const clearDefaults = ({ promoCode }) => {
    if (promoCode === PROMO_CODE_DEFAULT || promoCode === PROMO_CODE_INVALID) {
      setPromoCode("")
    }
  }

  const updateInput = (event) => {
    setPromoCode(event.target.value.toUpperCase())
  }

  return (
    <div id={promoCodeId} aria-label="promo-code-input-area" className={promoCodeValid ?
      "relative max-w-sm  bg-gradient-to-b from-accent-color-gradient to-accent-color rounded-b-3xl shadow-card duration-500 z-10 promo-code-hidden"
      : "relative max-w-sm mx-[1px] bg-gradient-to-b from-accent-color-gradient to-accent-color rounded-b-3xl shadow-card duration-500 z-10 promo-code-shown"}>
      <input
        aria-label="promo-code-input"
        className="pt-9 2xs:pt-10 mx-auto w-full text-white bg-transparent font-inter font-bold text-xs 2xs:text-sm text-center rounded-b-3xl no-outline z-10"
        type="text"
        value={promoCode}
        maxLength={16}
        spellCheck="false"
        onFocus={() => clearDefaults({ promoCode })}
        onChange={(event) => updateInput(event)}
      />
      <p className="mx-auto w-[140px] 2xs:w-[160px] pb-5 2xs:pb-6 border-t border-white"></p>
      <div aria-label="invalid-promocode-area" className={promoCodeValid ? "absolute items-center w-full h-[25px] -mt-3 hidden" : "absolute flex items-center w-full h-[25px] -mt-3 invalid-promo-code-bounce"}>
        <div className="h-[25px] w-[25px] mx-auto bg-gradient-to-t from-card-bg-bottom to-card-bg-top border border-white border-solid rounded-full shadow-card fill-black z-10">
          <svg width="36" height="36" viewBox="7 7 40 40">
            <path fillRule="evenodd" clipRule="evenodd" d="M20 11C21.1046 11 22 11.8954 22 13L22 21C22 22.1046 21.1046 23 20 23C18.8954 23 18 22.1046 18 21L18 13C18 11.8954 18.8954 11 20 11Z" />
            <circle cx="20" cy="27" r="2" />
          </svg>
        </div>
      </div>
    </div>
  )
}

export default PromoCodeInput