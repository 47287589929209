import React from "react"

const ProductPageFooter = () => {
  return (
    <footer className="product-page-footer-container">
      <svg viewBox="0 0 459 150" className="fill-footer-black">
        <path xmlns="http://www.w3.org/2000/svg" d="M0 0C0 21.7982 17.6909 39.4719 39.5104 39.4719H419.49C441.309 39.4719 459 21.7982 459 0V388H0V0Z" />
      </svg>
      <div className="product-page-footer-area">
        <div aria-label="product-page-footer" className="product-page-footer">
          <div className="col-span-1">
            <svg viewBox="0 0 55 41" className="fill-white">
              <path xmlns="http://www.w3.org/2000/svg" d="M53.3053 13.8236C53.7129 13.4996 54.3191 13.8027 54.3191 14.3148V35.6889C54.3191 38.4587 52.072 40.7058 49.3022 40.7058H5.82233C3.05258 40.7058 0.80542 38.4587 0.80542 35.6889V14.3253C0.80542 13.8027 1.40118 13.51 1.81925 13.834C4.16048 15.6526 7.26469 17.9625 17.9256 25.7074C20.131 27.317 23.8519 30.7034 27.5623 30.6825C31.2936 30.7138 35.0876 27.2543 37.2094 25.7074C47.8703 17.9625 50.9641 15.6422 53.3053 13.8236ZM27.5623 27.3274C29.9871 27.3692 33.4781 24.2755 35.234 23.0003C49.1036 12.9352 50.1593 12.0572 53.3576 9.54874C53.9638 9.0784 54.3191 8.34677 54.3191 7.57333V5.58747C54.3191 2.81771 52.072 0.570557 49.3022 0.570557H5.82233C3.05258 0.570557 0.80542 2.81771 0.80542 5.58747V7.57333C0.80542 8.34677 1.16078 9.06795 1.76699 9.54874C4.96528 12.0467 6.02092 12.9352 19.8906 23.0003C21.6465 24.2755 25.1374 27.3692 27.5623 27.3274Z" />
            </svg>
          </div>
          <div className="col-span-3 mt-[2%] 2xs:mt-[1%]">
            <p className="font-inter font-bold text-subscription-gray text-[0.7rem] 2xs:text-xs text-left leading-3">The Q Experience support mail</p>
            <p className="font-inter font-bold text-white text-[0.85rem] 2xs:text-sm text-left">q-support@nextfour.com</p>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default ProductPageFooter