import { useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"

/**
 * Check if theme color is already set for session.
 * Prevents resetting theme back to default "qexperience" if user manages to
 * go back to app root with e.g. browser back button without flavour query
 * parameter. App theme should be set only when first entering app through user
 * session creation from back-end, which also provides flavour query parameter.
 * @returns {boolean}
 */
const isThemeColorSet = () => {
  let appFlavour = sessionStorage.getItem("appFlavour")
  if (appFlavour === null) {
    return false
  }
  return true
}

/**
 * Change site accent color CSS variable based on flavour query parameter.
 * @param {string} flavour
 * @returns {void}
 */
const setThemeColor = (flavour) => {
  var appFlavour = flavour
  var themeColor = ""
  var themeColorGradient = ""

  switch (flavour) {
    case "buster":
      themeColor = "255, 105, 0, 1"
      themeColorGradient = "195, 45, 0, 1"
      break
    case "cross":
      themeColor = "214, 43, 31, 1"
      themeColorGradient = "154, 0, 0, 1"
      break
    case "yamarin":
      themeColor = "13, 95, 125, 1"
      themeColorGradient = "0, 35, 65, 1"
      break
    default:
      themeColor = "38, 188, 98, 1"
      themeColorGradient = "0, 128, 38, 1"
      appFlavour = "qexperience"
  }

  sessionStorage.setItem("appFlavour", appFlavour)
  sessionStorage.setItem("themeColor", themeColor)
  sessionStorage.setItem("themeColorGradient", themeColorGradient)
}

const initApp = (flavour) => {
  const initTheme = isThemeColorSet()
  if (!initTheme) {
    setThemeColor(flavour)
  }
}

const App = () => {
  const { flavour } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    initApp(flavour)
    navigate("/products", { replace: true })
  }, [flavour, navigate])
}

export default App
