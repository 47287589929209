import React from "react"
import PropTypes from "prop-types"

const LoadingSpinner = ({ containerStyle, iconColor = "fill-[#c9c9c9]" }) => {
  return (
    <div className={`flex justify-center h-screen ${containerStyle}`}>
      <svg width="98" height="98" viewBox="0 0 96.83 96.83" className={`animate-spin ${iconColor}`}>
        <path xmlns="http://www.w3.org/2000/svg" d="M48.41,18.65c-2.96,0-5.37,2.4-5.37,5.37v23.14c0,2.96,2.4,5.37,5.37,5.37s5.37-2.4,5.37-5.37V24.02c0-2.96-2.4-5.37-5.37-5.37Z" />
        <path fillRule="evenodd" d="M96.83,48.41c0,26.74-21.68,48.41-48.41,48.41S0,75.15,0,48.41,21.68,0,48.41,0s48.41,21.68,48.41,48.41Zm-10,0c0,21.22-17.2,38.41-38.41,38.41S10,69.63,10,48.41,27.2,10,48.41,10s38.41,17.2,38.41,38.41Z" />
      </svg>
    </div>
  )
}

LoadingSpinner.propTypes = {
  containerStyle: PropTypes.string,
  iconColor: PropTypes.string
}


export default LoadingSpinner


