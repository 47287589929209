import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { UseProductsContext } from "../contexts/ProductsContext"
import { client } from "../utils"

const UpgradeButton = () => {
  const navigate = useNavigate()
  const { product, upgradeOptions, appFlavour, oldProduct, setLoaderOverlay } = UseProductsContext()
  const [title, setTitle] = useState(product !== oldProduct ? "Upgrade" : "Show upgrade option")
  const [buttonStyle, setButtonStyle] = useState("bg-accent-color text-white border-2 border-t border-t-white")
  const [buttonDisabled, setButtonDisabled] = useState(false)

  useEffect(() => {
    if (product !== oldProduct) {
      setTitle("Upgrade")
    } else {
      upgradeOptions.length > 1 ?
        setTitle("Show upgrade options") : setTitle("Show upgrade option")
      setButtonStyle("bg-accent-color text-white border-2 border-t border-t-white")
    }
  }, [product, upgradeOptions, oldProduct])

  const onButtonClick = () => {
    // Current product is the one that user could upgrade to
    if (product !== oldProduct) {
      if (title === "Upgrade") {
        // Changes the button from "Upgrade" to "Purchase now" on first click to indicate that the price will be charged immediately
        setTitle("Purchase now")
        setButtonStyle("bg-white text-accent-color border-2 border-accent-color")
      } else {
        upgrade()
      }
    } else if (upgradeOptions.length === 1) { // Only one upgrade option, opens the card directly
      navigate("/product-details", { state: { product: upgradeOptions[0], upgradeOptions: upgradeOptions, appFlavour: appFlavour, oldProduct: product } })
    } else { // Multiple upgrade options shown in a list
      navigate("/upgrade-options", { state: { product: product, upgradeOptions: upgradeOptions, appFlavour: appFlavour, oldProduct: product } })
    }
  }

  const upgrade = () => {
    setButtonDisabled(true)
    setLoaderOverlay(true)
    client.post("/upgrade-subscription", {
      "old_price": oldProduct.id,
      "new_price": product.id
    }).then(() => {
      setLoaderOverlay(false)
      navigate("/success")
    }).catch((error) => {
      navigate("/error", { state: { "error": error.response.data.detail } })
    })
  }

  return (
    <button disabled={buttonDisabled} onClick={onButtonClick} aria-label="upgrade-button" className={`w-full py-2.5 2xs:py-3 px-3.5 font-inter font-bold text-sm 2xs:text-lg xs:text-xl border-solid rounded-lg shadow-button active:translate-y-[3px] mb-5 ${buttonStyle}`}>{title}</button>
  )
}

export default UpgradeButton

