import React from "react"
import PropTypes from "prop-types"
import { loadStripe } from "@stripe/stripe-js"
import { useNavigate } from "react-router-dom"
import { BlockReason, client, logger, logLevel } from "../utils"
import { UseProductsContext } from "../contexts/ProductsContext"
import { UsePromoCodeContext } from "../contexts/PromoCodeContext"
import { PROMO_CODE_DEFAULT, PROMO_CODE_INVALID } from "../constants"

const toCheckout = async ({ product, promoCode = null, navigate }) => {
  const stripe = await loadStripe(`${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`)
  let url = `/checkout-session?price=${product.id}&currency-code=${product.currency}`

  if (promoCode) {
    url += `&promo-code=${promoCode}`
  }

  client.get(url)
    .then((response) => {
      let session = response.data
      stripe.redirectToCheckout({ sessionId: session.id })
        .catch((error) => {
          logger({ level: logLevel.ERROR, data: `${error.response.data}` })
          let errorMessage = error.response.data.detail
          navigate("/error", { state: { "error": errorMessage } })
        })
    })
    .catch((error) => {
      let errorMessage = error.response.data.detail
      navigate("/error", { state: { "error": errorMessage } })
    })
}

const CardButton = ({ product }) => {
  const { appFlavour, setLoaderOverlay } = UseProductsContext()
  const { promoCode, setPromoCode, setPromoCodeValid } = UsePromoCodeContext()
  const navigate = useNavigate()

  const handleCheckout = () => {
    setLoaderOverlay(true)
    setPromoCodeValid(true)

    if (promoCode && (promoCode !== PROMO_CODE_DEFAULT && promoCode !== PROMO_CODE_INVALID)) {
      client.get(`/check-promo-code?code=${promoCode}&price=${product.id}`)
        .then(() => {
          toCheckout({ product, promoCode, navigate })
        })
        .catch(() => {
          handleInvalidPromoCode()
        })
    } else {
      toCheckout({ product, navigate })
    }
  }

  const handleCustomerPortal = () => {
    setLoaderOverlay(true)

    client.get("/customer-portal")
      .then((response) => {
        window.location.href = response.data
      })
      .catch((error) => {
        let errorMessage = error.response.data.detail
        navigate("/error", { state: { "error": errorMessage } })
      })
  }

  const getWebshopUrl = () => {
    let inhaApps = ["buster", "cross", "yamarin"]

    if (appFlavour === "qexperience") {
      return "https://shop.theqexperience.com/p/q-connect-sim"
    } else if (inhaApps.includes(appFlavour)) {
      return "https://shop.theqexperience.com/p/q-connect-sim-iwl"
    }
  }

  const handleGetFreeSIM = () => {
    setLoaderOverlay(true)
    window.location.href = getWebshopUrl()
  }

  const handleInvalidPromoCode = () => {
    setLoaderOverlay(false)
    setPromoCode(PROMO_CODE_INVALID)
    setPromoCodeValid(false)
  }

  return (
    <>
      {
        product.device_subscription.status && product.device_subscription.current_subscriber
          ?
          <button className="w-full py-2.5 2xs:py-3 px-3.5 font-inter font-bold text-sm 2xs:text-lg xs:text-xl border-solid rounded-lg shadow-button active:translate-y-[3px] bg-white text-accent-color border-2 border-accent-color" onClick={handleCustomerPortal}>Manage subscription</button>
          :
          product.status.blocked
            ?
            product.status.reason === BlockReason[0].value || product.status.reason === BlockReason[1].value
              ?
              <button className="card-button" onClick={handleGetFreeSIM}>Get free SIM card</button>
              :
              <div className="-mt-6" data-testid="no-product-card-button"></div>
            :
            product.trial.available
              ?
              <button className="card-button" onClick={handleCheckout}>{`Start with ${product.trial.period} day trial`}</button>
              :
              product.device_subscription.status === null
                ?
                <button className="card-button" onClick={handleCheckout}>Subscribe</button>
                :
                <div className="-mt-6" data-testid="no-product-card-button"></div>
      }
    </>
  )
}

CardButton.propTypes = {
  product: PropTypes.object,
}

export default CardButton
