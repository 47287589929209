import React from "react"
import Spinner from "./LoadingSpinner"

const StripeLoaderOverlay = () => {
  return (
    <div id="loader-overlay" aria-label="loader-overlay" className="loader-overlay hidden">
      <Spinner containerStyle={"items-center"} />
    </div>
  )
}

export default StripeLoaderOverlay