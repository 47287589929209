import React from "react"
import PropTypes from "prop-types"

const CardImage = ({ url, showBlurLine = true, imageFill = "object-cover" }) => {
  return (
    <div aria-label="card-image" className="col-start-1 row-start-1" >
      <img src={url} alt="" className={`${imageFill} w-full h-28 md:h-40`}></img>
      {showBlurLine && <div className="h-2 w-full bg-gradient-to-b relative from-transparent to-white bottom-2" ></div>}
    </div >
  )
}

CardImage.propTypes = {
  url: PropTypes.string,
  showBlurLine: PropTypes.bool,
  imageFill: PropTypes.string,
}

export default CardImage