import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import PropTypes from "prop-types"
import CardImage from "./CardImage"
import CardTitle from "./CardTitle"
import CardPrice from "./CardPrice"
import CardTrialRibbon from "./CardTrialRibbon"
import Checkmark from "./Checkmark"
import { BlockReason } from "../utils"

const ProductCard = ({ product, upgradeOptions, appFlavour, mobileSubActive }) => {
  const navigate = useNavigate()
  const [checkmarkVisible, setCheckmarkImageVisible] = useState(false)
  const [opacity, setOpacity] = useState("opacity-100")
  const [border, setBorder] = useState("shadow-card my-1")

  useEffect(() => {
    const activeSubscription = product.device_subscription.status || product.status.reason === BlockReason[3].value;
    setCheckmarkImageVisible(activeSubscription)

    if (activeSubscription) {
      setBorder("border-solid border-accent-color border-4 drop-shadow-md shadow-[0_0_1px_0_rgba(0,0,0,1)]")
    }

    if (product.product.metadata.type !== "MOBILE_SUBSCRIPTION" && product.status.reason === BlockReason[5].value) {
      setOpacity("opacity-50")
    }

  }, [product, mobileSubActive])

  const openCard = () => {
    navigate("/product-details", { state: { product: product, upgradeOptions: upgradeOptions, appFlavour: appFlavour, oldProduct: product } })
  }

  return (
    <button aria-label="product-card-button" className={`${opacity} ${border} relative bg-gradient-to-t from-card-bg-bottom to-card-bg-top rounded-2xl z-20 overflow-hidden w-full`} onClick={() => openCard()}>
      <CardTrialRibbon product={product} />
      <div className="grid grid-cols-1">
        {
          product.product.images !== undefined && product.product.images.length !== 0 &&
          <CardImage url={product.product.images[0]} showBlurLine={product.product.metadata.type !== "MOBILE_SUBSCRIPTION"} imageFill={product.product.metadata.type === "MOBILE_SUBSCRIPTION" ? "object-contain" : "object-cover"} />
        }
        {checkmarkVisible && <Checkmark />}
      </div>
      <div className="font-inter text-black mx-1 text-center">
        <CardTitle productName={product.product.name} />
        <CardPrice productPrice={product.prices} currency={product.currency} />
      </div>
    </button>
  )
}

ProductCard.propTypes = {
  product: PropTypes.object,
  upgradeOptions: PropTypes.array,
  appFlavour: PropTypes.string,
  mobileSubActive: PropTypes.bool,
}

export default ProductCard
