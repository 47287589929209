import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"

const ErrorPage = () => {
  const [error, setError] = useState("Something went wrong")
  const location = useLocation()

  useEffect(() => {
    if (location.state && location.state.error) {
      setError(location.state.error)
    }
  }, [location])

  return (
    <div className="flex flex-col align-middle justify-center bg-slate-800 min-h-screen">
      <p className="text-zinc-50 text-2xl font-bold text-center mx-10">
        {error}
      </p>
    </div>
  )
}

export default ErrorPage