import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { client, logger, logLevel } from "../utils"
import { ProductsProvider } from "../contexts/ProductsContext"
import bgPattern from "../assets/images/bgPattern.png"
import ProductCardList from "../components/ProductCardList"
import ProductPageButtonArea from "../components/ProductPageButtonArea"
import ProductPageFooter from "../components/ProductPageFooter"
import StripeLoaderOverlay from "../components/StripeLoaderOverlay"
import { INHA_CUSTOMER_NUMBER } from "../constants"
import LoadingSpinner from "../components/LoadingSpinner"

/**
 * Validate product by checking it has:
 *  - price is greater than zero
 *  - recurring payment interval set
 *  - "company" and "type" set in product metadata
 * 
 * Return true if valid product, else false.
 * @param {Object<string, any>} product 
 * @returns {boolean}
 */
const validateProductFields = (product) => {
  if (
    (product.unit_amount && product.unit_amount > 0) &&
    (product.recurring && product.recurring.interval) &&
    product.product.metadata.type
  ) {
    return true
  }
  return false
}

/**
 * Parse product feature list from metadata object.  
 * Return list holding features as strings.
 * @param {Object<string, string>} metadata
 * @returns {Array<string>}
 */
const parseProductFeatures = (metadata) => {
  var features = []
  if ("description" in metadata) {
    features = metadata.description.split(";")
  }
  return features
}

/**
 * Parse product name if needed.  
 * Currently only Inha mobile products need this to
 * differentiate naming between different boat brands.
 * @param {Object<string, string>} metadata
 * @returns {string}
 */
const parseProductName = (product, appFlavour) => {
  if (product.product.metadata.type === "MOBILE_SUBSCRIPTION" &&
    product.product.metadata.company === INHA_CUSTOMER_NUMBER) {
    let productName = product.product.name.replace("IWL", appFlavour.charAt(0).toUpperCase() + appFlavour.slice(1))
    return productName
  }
  return product.product.name
}

/**
 * Parse monthly and annual prices for subscription based on payment interval.
 * Return object holding main and minor currency unit amounts and payment interval for monthly and yearly prices.
 * @param {number} price - Price in minor units e.g. cents
 * @param {number} minorUnit - Ratio of minor units to main unit as exponent of 10
 * @param {string} paymentInterval - Can be "month" or "year". "Day" is used for test products.
 * @returns {Object<string, string>}
 */
const parseProductPrice = (price, minorUnit, paymentInterval) => {
  let monthlyPrice = 0
  let yearlyPrice = 0
  let priceInMainUnits = price / 10 ** minorUnit

  switch (paymentInterval) {
    case "month":
      monthlyPrice = priceInMainUnits
      yearlyPrice = priceInMainUnits * 12
      break
    case "year":
      monthlyPrice = priceInMainUnits / 12
      yearlyPrice = priceInMainUnits
      break
    case "day":
      monthlyPrice = priceInMainUnits * 365 / 12
      yearlyPrice = priceInMainUnits * 365
      break
    default:
      logger({ level: logLevel.ERROR, data: "Unsupported payment interval for parsing product price" })
  }

  monthlyPrice = monthlyPrice.toFixed(2)
  yearlyPrice = yearlyPrice.toFixed(2)

  let monthlyPriceSplit = monthlyPrice.split(".")
  let yearlyPriceSplit = yearlyPrice.split(".")

  monthlyPriceSplit.length < 2 ? monthlyPriceSplit[1] = "00" : monthlyPriceSplit[1] = monthlyPriceSplit[1].padEnd(2, 0)
  yearlyPriceSplit.length < 2 ? yearlyPriceSplit[1] = "00" : yearlyPriceSplit[1] = yearlyPriceSplit[1].padEnd(2, 0)

  let productPrices = {
    monthlyMains: monthlyPriceSplit[0],
    monthlyFractions: monthlyPriceSplit[1],
    yearlyMains: yearlyPriceSplit[0],
    yearlyFractions: yearlyPriceSplit[1],
    paymentInterval: paymentInterval
  }

  return productPrices
}

const ProductPage = () => {
  const [products, setProducts] = useState([])
  const [filteredProducts, setFilteredProducts] = useState([])
  const [customerStatus, setCustomerStatus] = useState(false)
  const [loaderOverlay, setLoaderOverlay] = useState(false)
  const [mobileSubActive, setMobileSubActive] = useState(false)
  const appFlavour = window.sessionStorage.getItem("appFlavour")
  const navigate = useNavigate()

  useEffect(() => {
    document.documentElement.style.setProperty("--color-theme", sessionStorage.getItem("themeColor"))
    document.documentElement.style.setProperty("--color-theme-gradient", sessionStorage.getItem("themeColorGradient"))
    window.addEventListener("pageshow", () => {
      setLoaderOverlay(false)
    })
  }, [])

  useEffect(() => {
    const getCustomerStatus = () => {
      client.get("/customer-status")
        .then((response) => {
          let customer = response.data
          customer.exists ? setCustomerStatus(true) : setCustomerStatus(false)
        })
        .catch((error) => {
          let errorMessage = error.response.data.detail
          navigate("/error", { state: { "error": errorMessage } })
        })
    }
    getCustomerStatus()
  }, [navigate])

  useEffect(() => {
    const getProducts = () => {
      client.get("/products")
        .then((response) => {
          if (response.data && Array.isArray(response.data)) {
            let products = response.data.filter(validateProductFields)

            products.forEach((product) => {
              product.features = parseProductFeatures(product.product.metadata)
              product.product.name = parseProductName(product, appFlavour)
              product.prices = parseProductPrice(
                product.unit_amount, product.currency_minor_unit, product.recurring.interval
              )
              if (product.product.metadata.type === "MOBILE_SUBSCRIPTION" && product.device_subscription.status) {
                setMobileSubActive(true)
              }
            })

            setProducts(products)
          }
        })
        .catch((error) => {
          let errorMessage = error.response.data.detail
          navigate("/error", { state: { "error": errorMessage } })
        })
    }
    getProducts()
  }, [appFlavour, navigate])

  // Spinner loading animation overlay for waiting async responses
  useEffect(() => {
    let delay = 200
    setTimeout(() => {
      let overlay = document.getElementById("loader-overlay")
      if (overlay) {
        loaderOverlay ? overlay.classList.remove("hidden") : overlay.classList.add("hidden")
      }
    }, delay)
  }, [loaderOverlay])

  if (!filteredProducts) {
    return null
  }
  return (
    <div className="relative bg-repeat bg-left-top top bg-bg-gray z-0" style={{ backgroundImage: `url(${bgPattern})` }}>
      <div className="fixed inset-0 bg-gradient-to-b from-bg-overlay-top to-bg-overlay-bottom z-10"></div>
      <ProductsProvider value={{ appFlavour, products, filteredProducts, mobileSubActive, setFilteredProducts, setLoaderOverlay }}>
        <div className="flex flex-col min-h-screen justify-center">
          <StripeLoaderOverlay />
          <ProductPageButtonArea customerStatus={customerStatus} />
          {products.length > 0 ? <ProductCardList /> : <LoadingSpinner containerStyle="z-10" iconColor="fill-black" />}
        </div>
      </ProductsProvider>
      <ProductPageFooter />
    </div >
  )
}

export default ProductPage
