import React, { useEffect, useState } from "react"
import { UseProductsContext } from "../contexts/ProductsContext"

const ProductFilterButton = () => {
  const { products, setFilteredProducts } = UseProductsContext()
  const [productFilter, setProductFilter] = useState("")

  // Component initializer
  useEffect(() => {
    let activeProductFilter = window.sessionStorage.getItem("productFilter")
    activeProductFilter ? setProductFilter(activeProductFilter) : setProductFilter("year")
  }, [])

  // Product filter updates and filter button transformations
  useEffect(() => {
    let filterBtnSlider = document.getElementById("product-filter-button-slider")
    let monthlyText = document.getElementById("product-filter-text-monthly")
    let yearlyText = document.getElementById("product-filter-text-annual")

    // Animate button overlay transition between two states when the filter button triggers filter change
    let filterChanged = window.sessionStorage.getItem("filterChanged")
    if (filterChanged === "true") {
      productFilter === "month" ? filterBtnSlider.classList.add("translate-x-[100%]") : filterBtnSlider.classList.remove("translate-x-[100%]")
      filterBtnSlider.classList.add("duration-700")

      if (productFilter === "month") {
        filterBtnSlider.classList.add("-left-1")
        filterBtnSlider.classList.remove("left-1")
        monthlyText.classList.add("text-white")
        monthlyText.classList.remove("text-subscription-gray")
        yearlyText.classList.add("text-subscription-gray")
        yearlyText.classList.remove("text-white")
      } else {
        filterBtnSlider.classList.add("left-1")
        filterBtnSlider.classList.remove("-left-1")
        monthlyText.classList.add("text-subscription-gray")
        monthlyText.classList.remove("text-white")
        yearlyText.classList.add("text-white")
        yearlyText.classList.remove("text-subscription-gray")
      }
    } else {
      // Filter button overlay CSS defaults to "year" when page loaded.
      // This sets it for "month" if active filter is "month" in session storage.
      if (productFilter === "month") {
        filterBtnSlider.classList.add("translate-x-[100%]", "-left-1")
        filterBtnSlider.classList.remove("duration-700", "left-1")
        monthlyText.classList.add("text-white")
        monthlyText.classList.remove("text-subscription-gray")
        yearlyText.classList.add("text-subscription-gray")
        yearlyText.classList.remove("text-white")
      }
    }

    window.sessionStorage.setItem("filterChanged", "false")

    let filteredProducts = products.filter((product) => {
      if (product.prices.paymentInterval === "day" && productFilter === "month") {
        return true
      }
      return product.prices.paymentInterval === productFilter
    })

    setFilteredProducts(filteredProducts)
  }, [products, productFilter, setFilteredProducts])

  const handleClick = () => {
    let updatedFilter = ""
    productFilter === "month" ? updatedFilter = "year" : updatedFilter = "month"

    setProductFilter(updatedFilter)
    window.sessionStorage.setItem("productFilter", updatedFilter)
    window.sessionStorage.setItem("filterChanged", "true")
  }

  return (
    <div className="product-filter-button-container">
      <div className="product-filter-button-grid-container">
        <div className="product-filter-button-area">
          <div id="product-filter-text-annual" className="product-filter-button-text-annual">Annual</div>
          <div id="product-filter-text-monthly" className="product-filter-button-text-monthly">Monthly</div>
          <div id="product-filter-button-slider" className="product-filter-button-slider"></div>
          <button
            aria-label="product-filter-button"
            className="product-filter-button"
            onClick={handleClick}
          >
          </button>
        </div>
      </div>
    </div>
  )
}

export default ProductFilterButton