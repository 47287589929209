import React, { useId, useState } from "react"
import CardButtonArea from "./CardButtonArea"
import CardFooterArea from "./CardFooterArea"
import CardPrice from "./CardPrice"
import CardFeatures from "./CardFeatures"
import CardTitle from "./CardTitle"
import CardStatus from "./CardStatus"
import CloseButton from "./BackButton"
import { PromoCodeProvider } from "../contexts/PromoCodeContext"
import { UseProductsContext } from "../contexts/ProductsContext"
import FullCardImage from "./FullCardImage"
import { useNavigate } from "react-router-dom"
import CardTrialRibbon from "./CardTrialRibbon"

const SelectedCard = () => {
  const promoCodeId = useId()
  const [promoCode, setPromoCode] = useState("")
  const [promoCodeValid, setPromoCodeValid] = useState(true)
  const { product } = UseProductsContext()
  const navigate = useNavigate()

  return (

    <div className="pt-4 flex flex-col justify-center items-center">
      <div className="w-5/6 md:w-96">
        <PromoCodeProvider value={{ promoCodeId, promoCode, setPromoCode, promoCodeValid, setPromoCodeValid }} className="drop-shadow-lg">
          <div aria-label="card-content-area" className="container relative bg-gradient-to-t from-card-bg-bottom to-card-bg-top rounded-2xl shadow-card z-20 items-center md:max-w-sm flex-none px-1 overflow-hidden">
            <CardTrialRibbon product={product} styling={"translate-x-[32%] translate-y-[14%] px-20 py-2"} />
            <CloseButton onClick={() => navigate(-1)} />
            <CardTitle productName={product.product.name} styling={product.device_subscription.trial_period || (!product.status.blocked && product.trial.available) ? "text-accent-color font-bold text-2xl md:text-3xl text-center mt-3 mb-1 mx-4" : "text-accent-color font-bold text-2xl md:text-3xl text-center -mt-1 mb-1 mx-4"} />
            {product.product.images !== undefined && product.product.images.length !== 0 && <FullCardImage url={product.product.images[0]} />}
            <CardPrice productPrice={product.prices} currency={product.currency} mainPriceStyle="py-1 font-bold text-lg text-center" secondaryPriceStyle="text-grey-400 -my-1 text-sm pb-3 text-center" />
            <CardFeatures
              productFeatures={product.features}
              productInfoHandle={product.product.metadata.info_handle}
            />
            <CardStatus subscriptionStatus={product.device_subscription} />
            <CardButtonArea />
          </div>
          <CardFooterArea />
        </PromoCodeProvider>
      </div>
    </div>

  )
}

export default SelectedCard