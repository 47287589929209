import React from "react"
import PropTypes from "prop-types"

const CardTrialRibbon = ({ product, styling = "translate-x-[30%] translate-y-[20%] py-0.5 px-10" }) => {
  return (
    <>
      {
        product.device_subscription.trial_period || product.trial.available
          ?
          <div aria-label="card-trial-ribbon" className={`${styling} absolute rotate-[35deg] top-0 right-0 origin-center bg-gradient-to-r from-trial-ribbon-left to-trial-ribbon-right font-inter text-center text-white font-bold text-lg shadow-card-trial-ribbon`}>TRIAL</div>
          :
          null
      }
    </>
  )
}

CardTrialRibbon.propTypes = {
  product: PropTypes.object,
  styling: PropTypes.string,
}

export default CardTrialRibbon