import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { BlockReason } from "../utils"

const CardBlocked = ({ product }) => {
  const [blockReason, setBlockReason] = useState("")

  useEffect(() => {
    let reason = product.status.reason
    setBlockReason(BlockReason[reason].name)
  }, [product.status.reason])

  return (
    <div aria-label="card-blocked-footer" className="card-blocked-area">
      <p className="card-blocked-text">
        {`${blockReason}`}
      </p>
    </div>
  )
}

CardBlocked.propTypes = {
  product: PropTypes.object,
}

export default CardBlocked