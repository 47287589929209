import React from "react"
import CardBlocked from "./CardBlocked"
import PromoCodeInput from "./PromoCodeInput"
import { UseProductsContext } from "../contexts/ProductsContext"
import { BlockReason } from "../utils"

const CardFooterArea = () => {
  const { product, upgradeOptions } = UseProductsContext()
  const isUpgrade = product === upgradeOptions[0];
  const showBlock = !isUpgrade || (isUpgrade && product.status.reason === BlockReason[4].value)

  return (
    <>
      {
        product.status.blocked && showBlock
          ?
          <CardBlocked product={product} />
          :
          !product.trial.available && product.device_subscription.status === null
            ?
            <PromoCodeInput />
            :
            null
      }
    </>
  )
}

export default CardFooterArea