import React from "react"
import PropTypes from "prop-types"

const CardTitle = ({ productName, styling = "text-accent-color font-bold text:xs md:text-lg" }) => {
  return (
    <p aria-label="card-title" className={styling}>
      {productName}
    </p>
  )
}

CardTitle.propTypes = {
  productName: PropTypes.string,
  styling: PropTypes.string
}

export default CardTitle