import axios from "axios"

const apiPrefix = "internal-api/v1"

const base = process.env.NODE_ENV === "development" ? "https://localhost:8000" : window.location.origin

const client = axios.create({
  baseURL: new URL(`${apiPrefix}`, `${base}`).toString()
})

class LogLevel {
  constructor() {
    this.INFO = "info"
    this.ERROR = "error"
  }
}

const logLevel = new LogLevel()

const logger = ({ level, data }) => {
  let logEntry = {
    level: level,
    data: data
  }
  client.post("/front-end-log", logEntry)
}

class BlockReason {
  static 0 = new BlockReason("No device SIM", 0)
  static 1 = new BlockReason("Unsupported SIM", 1)
  static 2 = new BlockReason("Unsupported device", 2)
  static 3 = new BlockReason("Active subscription", 3)
  static 4 = new BlockReason("Cannot use a currency that differs from the ongoing subscriptions", 4)
  static 5 = new BlockReason("Feature activated by other subscription", 5)
  static 6 = new BlockReason("SIM is already in use in other device", 6)

  constructor(name, value) {
    this.name = name
    this.value = value
  }
  toString() {
    return `BlockReason.${this.name}`
  }
}

export { BlockReason, client, logger, logLevel }
