import React from "react"
import PropTypes from "prop-types"

const FullCardImage = ({ url }) => {
  return (
    <div aria-label="card-image" className="flex justify-center pt-3 pb-3">
      <img src={url} alt="" className="w-3/4 max-h-40 md:h-40 object-scale-down rounded-xl"></img>
    </div >
  )
}

FullCardImage.propTypes = {
  url: PropTypes.string,
}

export default FullCardImage