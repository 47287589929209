import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

/**
 * Get currency symbol based on currency  
 * @param {string} currency
 * @returns {string}
 */
const getCurrencySymbol = (currency) => {
  let symbol = ""

  switch (currency) {
    case "aud":
      symbol = "A$"
      break
    case "cad":
      symbol = "C$"
      break
    case "eur":
      symbol = "€"
      break
    case "gbp":
      symbol = "£"
      break
    case "jpy":
      symbol = "¥"
      break
    case "pln":
      symbol = "zł"
      break
    case "usd":
      symbol = "$"
      break
    case "nok":
      symbol = "kr"
      break
    case "sek":
      symbol = "kr"
      break
    default:
      symbol = currency.toUpperCase()
  }

  return symbol
}

/**
 * Get currency symbol placement based on currency  
 * @param {string} currency
 * @returns {boolean}
 */
const isSymbolAfterValue = (currency) => {
  const symbolsBefore = ["aud", "cad", "usd", "jpy", "gbp"];
  if (symbolsBefore.indexOf(currency) !== -1) {
    return false
  }
  return true
}

const CardPrice = ({ productPrice, currency, mainPriceStyle = "pt-1 font-bold text-sm md:text-lg pb-1", secondaryPriceStyle = "text-grey-400 -my-1 text-xs md:text-lg pb-3" }) => {

  const [priceMain, setPriceMain] = useState("")
  const [priceSecondary, setPriceSecondary] = useState("")

  useEffect(() => {
    const mains = productPrice.paymentInterval === "year" ? productPrice.yearlyMains : productPrice.monthlyMains;
    const mainsSecondary = productPrice.paymentInterval === "year" ? productPrice.monthlyMains : productPrice.yearlyMains;
    const fractions = productPrice.paymentInterval === "year" ? productPrice.yearlyFractions : productPrice.monthlyFractions;
    const fractionsSecondary = productPrice.paymentInterval === "year" ? productPrice.monthlyFractions : productPrice.yearlyFractions;
    const symbol = getCurrencySymbol(currency)

    isSymbolAfterValue(currency) ?
      setPriceMain(`${mains},${fractions}${symbol}`) : setPriceMain(`${symbol}${mains},${fractions}`)

    isSymbolAfterValue(currency) ?
      setPriceSecondary(`${mainsSecondary},${fractionsSecondary}${symbol}`) : setPriceSecondary(`${symbol}${mainsSecondary},${fractionsSecondary}`)

  }, [productPrice, currency])

  return (
    <>
      {
        productPrice.paymentInterval === "year"
          ?
          <>
            <p aria-label="card-price-annual" className={mainPriceStyle}>
              {priceMain} / year
            </p>
            <p aria-label="card-price-monthly" className={secondaryPriceStyle}>
              {priceSecondary} / month
            </p>
          </>
          :
          productPrice.paymentInterval === "month" || productPrice.paymentInterval === "day"
            ?
            <>
              <p aria-label="card-price-monthly" className={mainPriceStyle}>
                {priceMain} / month
              </p>
              <p aria-label="card-price-annual" className={secondaryPriceStyle}>
                {priceSecondary} / year
              </p>
            </>
            :
            null
      }
    </>
  )
}

CardPrice.propTypes = {
  productPrice: PropTypes.object,
  currency: PropTypes.string,
  mainPriceStyle: PropTypes.string,
  secondaryPriceStyle: PropTypes.string,
}

export default CardPrice