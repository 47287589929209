import React, { useEffect, useState } from "react"
import { UseProductsContext } from "../contexts/ProductsContext"
import CardButton from "../components/CardButton"
import CardPromoCodeButton from "./CardPromoCodeButton"
import UpgradeButton from "./UpgradeButton"
import { BlockReason } from "../utils"

const CardButtonArea = () => {
  const { product, upgradeOptions, oldProduct } = UseProductsContext()
  const [upgradeButtonVisible, setUpgradeButtonVisible] = useState(false)
  const [promoCodeButtonVisible, setPromoCodeButtonVisible] = useState(false)
  const [cardButtonVisible, setCardButtonVisible] = useState(true)

  useEffect(() => {
    const showUpgradeOptions = product.device_subscription.status && product.device_subscription.current_subscriber && upgradeOptions.length > 0 && product !== upgradeOptions
    const notBlocked = product.status.reason === null || product.status.reason === BlockReason[5].value
    const allowUpgrading = upgradeOptions && product !== oldProduct && notBlocked
    setUpgradeButtonVisible(showUpgradeOptions || allowUpgrading)

    const showPromoCode = !product.status.blocked && !product.trial.available && product.device_subscription.status === null;
    setPromoCodeButtonVisible(showPromoCode)

    const showButton = !upgradeButtonVisible || (upgradeButtonVisible && !product.trial.available)
    setCardButtonVisible(showButton)
  }, [product, upgradeOptions, upgradeButtonVisible, oldProduct])


  return (
    <div className="card-button-area">
      {
        upgradeButtonVisible && <UpgradeButton />
      }
      {
        cardButtonVisible && <CardButton aria-label="card-button" product={product} />
      }
      {
        promoCodeButtonVisible &&
        <div className="card-promo-code-button-area">
          <CardPromoCodeButton />
        </div>
      }
    </div>
  )
}

export default CardButtonArea