import React from "react"
import { useLocation, useNavigate } from "react-router-dom"
import CloseButton from "../components/BackButton"
import CardTitle from "../components/CardTitle"


const UpgradeOptions = () => {
  const location = useLocation();
  const navigate = useNavigate()

  return (
    <div className="relative bg-gradient-to-t from-card-bg-bottom to-card-bg-top z-20 w-full">
      <CloseButton onClick={() => navigate(-1)} />
      <CardTitle productName="Upgrade options" styling={"text-accent-color font-bold text-2xl md:text-3xl text-center -mt-3 mb-6"} />
      <div className="flex flex-col items-center ">
        {location.state.upgradeOptions.map((product) =>
          <button key={product.id} onClick={() => navigate("/product-details", { state: { product: product, upgradeOptions: product, appFlavour: location.state.appFlavour, oldProduct: location.state.oldProduct } })} className={"w-5/6 md:w-96 py-2.5 2xs:py-3 font-inter font-bold text-sm 2xs:text-lg xs:text-xl border-solid rounded-lg active:translate-y-[3px] mb-5 bg-accent-color text-white border-2 border-t border-t-white"}>{product.product.name}</button>
        )}
      </div>
    </div>
  )
}

export default UpgradeOptions