import React from "react"
import PropTypes from "prop-types"

const isSubscriptionValid = (subscriptionEnd) => {
  // TODO: Move this check to back-end to clear
  // possible problems with timezone differences
  let today = new Date()
  let validUntil = new Date(subscriptionEnd)

  if (today.getTime() < validUntil.getTime()) {
    return true
  } else {
    return false
  }
}

/**
 * Return subscription end date for product card in format: "MM.DD.YYYY"
 * @param {string} date - Date string format: "2022-10-28T12:53:24Z"
 * @returns {string}
 */
const parseSubscriptionEndDate = (date) => {
  const splitDate = date.split("T")
  const dateString = splitDate[0].split("-").reverse().join(".")

  return dateString
}

const CardStatus = ({ subscriptionStatus }) => {
  return (
    <>
      {(subscriptionStatus.status && subscriptionStatus.period_end)
        ?
        <div aria-label="card-status-area" className="pt-5 font-inter text-base 2xs:text-lg xs:text-xl text-left pl-10 pr-10">
          <p>
            <span className="text-black">Status - </span>
            <span
              aria-label="subscription-status"
              className={subscriptionStatus.status === "Active" ? "text-black" : "text-subscription-gray"}
            >
              {`${subscriptionStatus.status}`}
            </span>
          </p>
          <p>
            <span className="text-black">{subscriptionStatus.trial_period ? "Trial ends - " : "Valid until - "}</span>
            <span
              aria-label="subscription-validity-period"
              className={isSubscriptionValid(subscriptionStatus.period_end) ? "text-black" : "text-subscription-gray"}
            >
              {`${parseSubscriptionEndDate(subscriptionStatus.period_end)}`}
            </span>
          </p>
        </div>
        :
        null
      }
    </>
  )
}

CardStatus.propTypes = {
  subscriptionStatus: PropTypes.object,
}

export default CardStatus