import React from "react"
import PropTypes from "prop-types"
import ButtonImage from "../assets/images/arrow-back.svg"

const CloseButton = ({ onClick }) => {
  return (
    <button className="w-8 ml-3 mt-3">
      <img src={ButtonImage} alt="" onClick={onClick}></img>
    </button>
  )
}

CloseButton.propTypes = {
  onClick: PropTypes.func,
}

export default CloseButton