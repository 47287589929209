import React from "react"
import ReactDOM from "react-dom/client"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import App from "./App"
import ErrorPage from "./pages/Error"
import ProductPage from "./pages/Products"
import SuccessPage from "./pages/Success"
import "./index.css"
import ProductDetails from "./pages/ProductDetails"
import UpgradeOptions from "./pages/UpgradeOptions"

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/:flavour?" element={<App />} />
      <Route path="/products" element={<ProductPage />} />
      <Route path="/product-details" element={<ProductDetails />} />
      <Route path="/upgrade-options" element={<UpgradeOptions />} />
      <Route path="/error" element={<ErrorPage />} />
      <Route path="/success" element={<SuccessPage />} />
    </Routes>
  </BrowserRouter>
)
