import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { ProductsProvider } from "../contexts/ProductsContext"
import bgPattern from "../assets/images/bgPattern.png"
import ProductPageFooter from "../components/ProductPageFooter"
import StripeLoaderOverlay from "../components/StripeLoaderOverlay"
import SelectedCard from "../components/SelectedCard"

const ProductDetails = () => {
  const location = useLocation();
  const [product, setProduct] = useState(location.state.product)
  const [upgradeOptions, setupgradeOptions] = useState(location.state.upgradeOptions)
  const [oldProduct, setOldProduct] = useState(location.state.oldProduct)
  const [appFlavour] = useState(location.state.appFlavour)
  const [loaderOverlay, setLoaderOverlay] = useState(false)

  useEffect(() => {
    window.addEventListener("pageshow", () => {
      setLoaderOverlay(false)
    })
  }, [])

  // Spinner loading animation overlay for waiting async responses
  useEffect(() => {
    let delay = 200
    setTimeout(() => {
      let overlay = document.getElementById("loader-overlay")
      if (overlay) {
        loaderOverlay ? overlay.classList.remove("hidden") : overlay.classList.add("hidden")
      }
    }, delay)
  }, [loaderOverlay])

  useEffect(() => {
    setProduct(location.state.product)
    setupgradeOptions(location.state.upgradeOptions)
    setOldProduct(location.state.oldProduct)
  }, [location.state.product, location.state.upgradeOptions, location.state.oldProduct])

  return (
    <ProductsProvider value={{ product, upgradeOptions, appFlavour, oldProduct, setLoaderOverlay }}>
      <div className="relative bg-repeat bg-left-top bg-bg-gray z-0" style={{ backgroundImage: `url(${bgPattern})` }}>
        <div className="fixed inset-0 bg-gradient-to-b from-bg-overlay-top to-bg-overlay-bottom z-10"></div>
        <div className="flex flex-col min-h-screen justify-center">
          <StripeLoaderOverlay />
          <SelectedCard />
        </div>
        <ProductPageFooter />
      </div >
    </ProductsProvider>
  )
}

export default ProductDetails
