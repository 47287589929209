import React from "react"
import { useNavigate } from "react-router-dom"
import { client } from "../utils"
import { UseProductsContext } from "../contexts/ProductsContext"

const StripeAccountButton = () => {
  const { setLoaderOverlay } = UseProductsContext()
  const navigate = useNavigate()

  const handleCustomerPortal = () => {
    setLoaderOverlay(true)

    client.get("/customer-portal")
      .then((response) => {
        window.location.href = response.data
      })
      .catch((error) => {
        let errorMessage = error.response.data.detail
        navigate("/error", { state: { "error": errorMessage } })
      })
  }

  return (
    <div className="stripe-account-button-container">
      <div id="stripe-account-button" className="stripe-account-button-area">
        <div className="stripe-account-button-svg">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
            <path d="M20,20.6c3.5,0,6.3-2.8,6.3-6.3c0-3.5-2.8-6.3-6.3-6.3s-6.3,2.8-6.3,6.3C13.7,17.8,16.6,20.6,20,20.6z M20,11c1.8,0,3.3,1.5,3.3,3.3c0,1.8-1.5,3.3-3.3,3.3s-3.3-1.5-3.3-3.3C16.7,12.5,18.2,11,20,11z M31.7,28.2v2.3c0,0.8-0.7,1.5-1.5,1.5s-1.5-0.7-1.5-1.5v-2.3c0-1.9-1.5-3.4-3.4-3.4H14.6c-1.9,0-3.4,1.5-3.4,3.4v2.3c0,0.8-0.7,1.5-1.5,1.5s-1.5-0.7-1.5-1.5v-2.3c0-3.5,2.9-6.4,6.4-6.4h10.7C28.9,21.8,31.7,24.7,31.7,28.2z" />
          </svg>
        </div>
        <button
          aria-label="stripe-account-button"
          className="stripe-account-button"
          onClick={handleCustomerPortal}
        >
        </button>
      </div>
    </div>
  )
}

export default StripeAccountButton