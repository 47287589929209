import React from "react"
import PropTypes from "prop-types"
import ProductFilterButton from "./ProductFilterButton"
import StripeAccountButton from "./StripeAccountButton"

const ProductPageButtonArea = ({ customerStatus }) => {
  return (
    <div className="mx-8 mb-2 z-20 py-5">
      <div className="flex flex-row align-bottom gap-x-4 max-w-sm mx-auto">
        <ProductFilterButton />
        {
          customerStatus &&
          <StripeAccountButton />
        }
      </div>
    </div>
  )
}

ProductPageButtonArea.propTypes = {
  customerStatus: PropTypes.bool,
}

export default ProductPageButtonArea