import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { UseProductsContext } from "../contexts/ProductsContext"
import { QEXPERIENCE_WEB_URL } from "../constants"

const getProductInfoUrl = (flavour, productInfoHandle) => {
  let inhaApps = ["buster", "cross", "yamarin"]

  if (inhaApps.includes(flavour)) {
    return `${QEXPERIENCE_WEB_URL}/${productInfoHandle}-${flavour}`
  }
  return `${QEXPERIENCE_WEB_URL}/${productInfoHandle}`
}

const CardFeatures = ({ productFeatures, productInfoHandle }) => {
  const { appFlavour } = UseProductsContext()

  return (
    <div aria-label="card-features">
      {
        productFeatures.map((feature) =>
          <p key={feature} className="pt-1.5 pl-10 pr-10">
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="-2 -2 24 23" className="inline-block max-w-[18px] 2xs:max-w-[22px] xs:max-w-[24px] fill-black ">
                <path d="M15.243 0.653542C15.9046 1.15217 16.0367 2.09269 15.538 2.75425L6.55991 14.6661L1.02643 10.0357C0.391093 9.50409 0.307036 8.55807 0.838678 7.92273C1.37032 7.2874 2.31634 7.20334 2.95167 7.73499L6.06421 10.3395L13.1423 0.948559C13.641 0.286999 14.5815 0.154915 15.243 0.653542Z" />
              </svg>
            </span>
            <span className="pl-1 text-base 2xs:text-lg xs:text-xl">
              {`${feature}`}
            </span>
          </p>
        )
      }
      {
        productInfoHandle
          ?
          <p className="pt-1.5 pl-10 pr-10">
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="4 0 40 40" className="inline-block max-w-[18px] 2xs:max-w-[22px] xs:max-w-[24px] fill-black">
                <path d="M30.1436 5.30182C26.7506 2.92599 22.1078 3.70235 19.7736 7.03586L18.7171 8.54482C17.1527 10.7789 17.0224 13.5947 18.1279 15.9241L22.8455 9.18677C24.0125 7.52001 26.3339 7.13184 28.0305 8.31975C29.727 9.50767 30.1562 11.8218 28.9891 13.4886L24.2716 20.2259C26.8387 20.4681 29.44 19.3826 31.0043 17.1485L32.0609 15.6395C34.3951 12.306 33.5367 7.67765 30.1436 5.30182Z" />
                <path d="M15.9405 19.048C13.3734 18.8059 10.7721 19.8914 9.20777 22.1255L8.15118 23.6345C5.81703 26.968 6.67543 31.5963 10.0685 33.9721C13.4615 36.348 18.1043 35.5716 20.4385 32.2381L21.495 30.7291C23.0594 28.495 23.1898 25.6793 22.0842 23.3499L17.3666 30.0872C16.1996 31.754 13.8782 32.1421 12.1816 30.9542C10.4851 29.7663 10.0559 27.4521 11.223 25.7854L15.9405 19.048Z" />
                <path d="M22.2682 13.2802C22.8517 12.4468 24.0124 12.2527 24.8607 12.8466C25.709 13.4406 25.9236 14.5977 25.34 15.4311L17.9439 25.9938C17.3604 26.8272 16.1997 27.0213 15.3514 26.4273C14.5031 25.8334 14.2885 24.6763 14.8721 23.8429L22.2682 13.2802Z" />
              </svg>
            </span>
            <span className="pl-1 text-base 2xs:text-lg xs:text-xl underline underline-offset-4">
              <Link to={getProductInfoUrl(appFlavour, productInfoHandle)}>
                Read more
              </Link>
            </span>
          </p>
          :
          null
      }
    </div>
  )
}

CardFeatures.propTypes = {
  productFeatures: PropTypes.array,
  productInfoHandle: PropTypes.string
}

export default CardFeatures