import React from "react"
import ProductCard from "./ProductCard"
import { UseProductsContext } from "../contexts/ProductsContext"

const ProductCardList = () => {
  const { filteredProducts, appFlavour, mobileSubActive } = UseProductsContext()

  const getUpgradableProducts = (product) => {
    const products = product.recurring.interval === "year" ? product.product.metadata.upgrade_annual && product.product.metadata.upgrade_annual.split(";") : product.product.metadata.upgrade_monthly && product.product.metadata.upgrade_monthly.split(";");
    let upgrades = [];
    if (products !== null && products !== undefined && products.length > 0) {
      products.forEach(element => {
        let temp = filteredProducts.find((p) => p.id === element)
        if (temp) {
          upgrades = [...upgrades, temp]
        }
      });

      upgrades.sort((a, b) => a.product.metadata.sort_priority > b.product.metadata.sort_priority);

    }

    return upgrades
  }

  return (
    <div aria-label="product-list" className="grow mx-3 mb-3 z-20 ">
      <div className={"justify-center gap-y-4 gap-x-4 z-20 grid grid-cols-2 lg:grid-cols-6 lg:gap-y-10 lg:gap-x-10"}>
        {filteredProducts.map((product) =>
          <div
            key={product.id}
            aria-label="product-card-list-item"
            className="flex-basis overflow-hidden"
          >
            <ProductCard product={product} upgradeOptions={getUpgradableProducts(product)} appFlavour={appFlavour} mobileSubActive={mobileSubActive} />
          </div>
        )}
      </div>
    </div>
  )
}

export default ProductCardList