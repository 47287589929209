import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

const SuccessPage = () => {
  const navigate = useNavigate()
  const [countdown, setCountdown] = useState(5)

  useEffect(() => {
    const countdownTimer = setInterval(() => {
      setCountdown((countdown) => {
        return countdown - 1
      })
    }, 1000)

    return () => {
      clearInterval(countdownTimer)
    }
  }, [])

  return (
    countdown > 0
      ?
      <div className="flex flex-col align-middle justify-center bg-slate-800 min-h-screen">
        <div className="font-inter text-zinc-50 text-center mx-10">
          <p className="mb-6 text-2xl font-bold">Thank you for your purchase!</p>
          <p className="text-xl">{`Redirecting in ${countdown} seconds`}</p>
        </div>
      </div>
      :
      navigate("/products", { replace: true })
  )
}

export default SuccessPage