import React, { useId } from "react"
import { UsePromoCodeContext } from "../contexts/PromoCodeContext"
import { PROMO_CODE_DEFAULT } from "../constants"

const CardPromoCodeButton = () => {
  const { promoCodeId, promoCode, setPromoCode, promoCodeValid } = UsePromoCodeContext()
  const buttonArrowId = useId()

  const handleClick = () => {
    let arrow = document.getElementById(buttonArrowId)
    let input = document.getElementById(promoCodeId)
    const inputState = input.classList

    if (inputState.contains("promo-code-hidden")) {
      arrow.classList.add("rotate-180")
      input.classList.replace("promo-code-hidden", "promo-code-shown")
      if (promoCode === "") {
        setPromoCode(PROMO_CODE_DEFAULT)
      }
    } else {
      arrow.classList.remove("rotate-180")
      input.classList.replace("promo-code-shown", "promo-code-hidden")
    }
  }

  return (
    <>
      <p>Promocode</p>
      <svg id={buttonArrowId} viewBox="0 0 21 11" className={promoCodeValid ? "card-promo-code-button-arrow-svg" : "card-promo-code-button-arrow-svg rotate-180"}>
        <path d="M0.755611 3.08523C0.335051 2.74364 0.263031 2.12925 0.593191 1.69965L1.27346 0.814508C1.61642 0.368256 2.25995 0.292821 2.69682 0.647661L9.86953 6.47361C10.2369 6.77199 10.7631 6.77199 11.1305 6.47361L18.3032 0.647661C18.74 0.292821 19.3836 0.368257 19.7265 0.814509L20.4068 1.69965C20.737 2.12925 20.665 2.74364 20.2444 3.08523L11.1305 10.4879C10.7631 10.7863 10.2369 10.7863 9.86953 10.4879L0.755611 3.08523Z" />
      </svg>
      <button
        aria-label="promo-code-button"
        className="card-promo-code-button"
        onClick={handleClick}>
      </button>
    </>
  )
}

export default CardPromoCodeButton