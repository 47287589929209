import React, { createContext, useContext } from "react"
import PropTypes from "prop-types"

const PromoCodeContext = createContext(null)

const PromoCodeProvider = ({ value, children }) => {
  return (
    <PromoCodeContext.Provider value={value}>
      {children}
    </PromoCodeContext.Provider>
  )
}

const UsePromoCodeContext = () => {
  return useContext(PromoCodeContext)
}

PromoCodeProvider.propTypes = {
  value: PropTypes.object,
  children: PropTypes.array
}

export { PromoCodeProvider, UsePromoCodeContext }