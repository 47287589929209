import React from "react"

const Checkmark = () => {
  return (
    <div aria-label="checkmark" className="col-start-1 row-start-1 w-7 h-7 mt-2 ml-2 bg-accent-color rounded-full flex justify-center items-center border-white border-[1px]" >
      <svg width="16" height="15" viewBox="0 0 16 15" xmlns="http://www.w3.org/2000/svg" className="fill-white">
        <path fillRule="evenodd" clipRule="evenodd" d="M15.243 0.653542C15.9046 1.15217 16.0367 2.09269 15.538 2.75425L6.55991 14.6661L1.02643 10.0357C0.391093 9.50409 0.307036 8.55807 0.838678 7.92273C1.37032 7.2874 2.31634 7.20334 2.95167 7.73499L6.06421 10.3395L13.1423 0.948559C13.641 0.286999 14.5815 0.154915 15.243 0.653542Z" />
      </svg>
    </div >
  )
}

export default Checkmark