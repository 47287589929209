import React, { createContext, useContext } from "react"
import PropTypes from "prop-types"

const ProductsContext = createContext(null)

const ProductsProvider = ({ value, children }) => {
  return (
    <ProductsContext.Provider value={value}>
      {children}
    </ProductsContext.Provider>
  )
}

const UseProductsContext = () => {
  return useContext(ProductsContext)
}

ProductsProvider.propTypes = {
  value: PropTypes.object,
  children: PropTypes.object
}

export { ProductsProvider, UseProductsContext }